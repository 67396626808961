<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>Payment</v-card-title>
      <v-card-text>
        {{ payable }}
        <v-row>
          <v-col>
            <v-text-field data-cy="paymentDate" label="Date" type="date" v-model="payment.paymentDate" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="showDialog = false">Close</v-btn>
        <v-btn color="primary" @click="makePayment">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PayablePaymentDialog',

  props: {
    payable: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    error: null,
    payment: {
      paymentDate: new Date().toISOString().substring(0, 10)
    }
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    makePayment () {
      console.log('makePayment')
      fetch(`/api/accounting/payables/${this.payable.id}`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.payment)
      })
        .then(response => {
          if (response.ok) {
            this.showDialog = false
            this.$emit('reload')
          } else {
            this.error = response.statusText
          }
        })
    }
  }
}
</script>
