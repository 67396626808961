<template>
  <v-card>
    <v-card-title data-cy="recordInvoiceCard">Record Invoice</v-card-title>
    <v-data-table data-cy="lastRecordedInvoices" :items="invoices" :headers="headers">
      <template v-slot:top>
        <v-toolbar>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn data-cy="recordInvoice" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Record Invoice
              </v-btn>
            </template>
            <v-card>
              <v-form data-cy="recordInvoiceForm" v-model="validInvoice" ref="recordInvoiceForm"
                @submit.prevent="recordInvoice">
                <v-card-title>
                  <span class="text-h5">Record Invoice</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field data-cy="invoiceIssuer" v-model="invoiceIssuer" label="Issuer"
                          :rules="invoiceIssuerRules"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field data-cy="invoiceDateIssued" v-model="invoiceDateIssued" label="Date Issued"
                          :rules="invoiceDateIssuedRules"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field data-cy="invoiceIdentifier" v-model="invoiceIdentifier" label="Identifier"
                          :rules="invoiceIdentifierRules"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field data-cy="invoiceAmount" v-model="invoiceAmount" label="Amount"
                          :rules="invoiceAmountRules"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field data-cy="invoiceCurrency" v-model="invoiceCurrency" label="Currency"
                          :rules="invoiceCurrencyRules"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn data-cy="recordInvoiceClose" @click="close">
                    Cancel
                  </v-btn>
                  <v-btn data-cy="recordInvoiceSave" color="primary" type="submit">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.invoiceMetaData.dateRecorded`]="{ item }">
        <span>{{ formatDate(item.invoiceMetaData.dateRecorded) }}</span>
      </template>
      <template v-slot:[`item.invoice.date`]="{ item }">
        <span>{{ formatDate(item.invoice.date) }}</span>
      </template>
      <template v-slot:[`item.invoice.amount`]="{ item }">
        <money-format :value="item.invoice.amount.value" :currency-code="item.invoice.amount.currency" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MoneyFormat from 'vue-money-format'
import axios from 'axios'

export default {
  name: 'RecordInvoice',
  components: {
    MoneyFormat
  },

  data: () => ({
    headers: [
      {
        text: 'Recorded',
        align: 'start',
        value: 'invoiceMetaData.dateRecorded',
        sortable: false
      },
      {
        text: 'Issuer',
        align: 'start',
        value: 'invoice.issuer',
        sortable: false
      },
      { text: 'Date', align: 'start', value: 'invoice.date', sortable: false },
      {
        text: 'Amount',
        align: 'start',
        value: 'invoice.amount',
        sortable: false
      },
      {
        text: 'Identifier',
        align: 'start',
        value: 'invoice.identifier',
        sortable: false
      }
    ],
    invoices: [],

    dialog: false,
    validInvoice: false,

    invoiceIssuer: '',
    invoiceIssuerRules: [(v) => !!v || 'Issuer name is required'],

    invoiceDateIssued: '',
    invoiceDateIssuedRules: [(v) => !!v || 'Invoice date is required'],

    invoiceAmount: '',
    invoiceAmountRules: [(v) => !!v || 'Invoice amount is required'],

    invoiceCurrency: '',
    invoiceCurrencyRules: [(v) => !!v || 'Invoice currency is required'],

    invoiceIdentifier: '',
    invoiceIdentifierRules: [(v) => !!v || 'Invoice identifier is required']
  }),

  mounted: function () {
    axios.get('/api/accounting/invoices').then((response) => {
      this.invoices = response.data
    })
  },

  methods: {
    formatDate (value) {
      return new Date(value).toLocaleDateString()
    },

    recordInvoice () {
      if (this.$refs.recordInvoiceForm.validate()) {
        axios.post('/api/accounting/invoices', {
          issuer: this.invoiceIssuer,
          date: this.invoiceDateIssued,
          amount: parseFloat(this.invoiceAmount),
          currency: this.invoiceCurrency,
          identifier: this.invoiceIdentifier
        })

        this.dialog = false
      }
    },

    close () {
      this.dialog = false
    }
  }
}
</script>
