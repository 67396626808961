<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="form">
        <v-card-title>Add Bank Statement Entry</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field data-cy="entryDate" label="Entry Date" type="date" v-model="entry.entryDate" />
            </v-col>
            <v-col>
              <v-text-field data-cy="statementDate" label="Statement Date" type="date" v-model="entry.statementDate" />
            </v-col>
            <v-col>
              <v-text-field data-cy="transactionDate" label="Transaction Date" type="date"
                v-model="entry.transactionDate" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field data-cy="amount" label="Amount" v-model="entry.amount" />
            </v-col>
            <v-col>
              <v-text-field data-cy="description" label="Description" v-model="entry.description" />
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="formCancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="formSave" color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formatting from '@/mixins/formatting'

export default {
  name: 'AddEntryDialog',
  components: {},
  mixins: [formatting],
  props: {
    show: {
      type: Boolean,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    error: null,
    entry: {
      entryDate: new Date().toISOString().substring(0, 10)
    }
  }),
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    save () {
      const entryToSave = {
        accountId: this.account.accountId,
        entryDate: this.entry.entryDate,
        statementDate: this.entry.statementDate,
        transactionDate: this.entry.transactionDate,
        amount: this.entry.amount,
        description: this.entry.description
      }

      fetch(`/api/accounting/bank-accounts/${this.account.accountId}/entries`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(entryToSave)
      })
        .then(response => {
          if (response.ok) {
            this.showDialog = false
            this.$emit('reload')
          } else {
            response.json().then(data => {
              this.error = data.statusText
            })
          }
        })
    }
  }
}
</script>
