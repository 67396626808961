<template>
  <div>
    <h1>Invoicing</h1>
    <ul>
      <li>Write invoice (email, PDF)</li>
      <li>Based on products and inventory</li>
      <li>Free content invoice</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InvoicingHome'
}
</script>
