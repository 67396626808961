<template>
  <v-data-table :headers="headers" :items="items">
    <template v-slot:top>
      <v-toolbar>
        <v-btn data-cy="addDialog" color="primary" dark @click="showAddDialog = true">Add</v-btn>
        <AddBankAccountDialog :show="showAddDialog" @input="showAddDialog = $event" v-on:reload="loadBankAccounts()" />
      </v-toolbar>
    </template>
    <template v-slot:[`item.accountName`]="{ item }">
      <router-link :to="{ name: 'AccountingBankAccount', params: { accountId: item.accountId } }">{{
        item.accountName }}</router-link>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'BankAccounts',
  components: {
    AddBankAccountDialog: () => import('@/views/accounting/AddBankAccountDialog.vue')
  },
  data () {
    return {
      headers: [
        { text: 'Account Name', value: 'accountName' },
        { text: 'Account Number', value: 'accountNumber' },
        { text: 'Balance', value: 'balance' }
      ],
      items: [],
      showAddDialog: false
    }
  },

  beforeMount () {
    this.loadBankAccounts()
  },

  methods: {
    loadBankAccounts () {
      fetch('/api/accounting/bank-accounts', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Error loading bank accounts')
          }
        })
        .then(response => {
          this.items = response
        })
    }
  }
}
</script>
