<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="formPayable">
        <v-card-title>Add Payable</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select data-cy="invoiceVendor" label="Vendor" :items="vendors" item-text="text" item-value="value"
                v-model="invoice.vendorId" />
            </v-col>
            <v-col>
              <v-text-field data-cy="invoiceDate" label="Date" type="date" v-model="invoice.payableDate" />
            </v-col>
            <v-col>
              <v-text-field data-cy="invoiceId" label="Invoice ID" v-model="invoice.invoiceNumber" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field data-cy="invoiceAmount" label="Amount" v-model="invoice.payableAmount" />
            </v-col>
            <v-col>
              <v-checkbox v-model="invoice.paid" label="Already paid?"></v-checkbox>
            </v-col>
            <v-col>
              <v-file-input label="Invoice scan" filled prepend-icon="mdi-camera" v-model="invoiceScan"></v-file-input>
              {{ $t('terms.upload') }} {{ uploadPercentCompleted }} %
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-btn data-cy="newVendor" @click="showNewVendorDialog = true">New Vendor</v-btn>
            <v-spacer></v-spacer>
            <v-btn data-cy="formPayableCancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="formPayableSave" color="primary" @click="savePayable">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
    <NewVendorDialog :show="showNewVendorDialog" @input="showNewVendorDialog = $event" @newVendor="vendorAdded" />
  </v-dialog>
</template>

<script>
import NewVendorDialog from './NewVendorDialog.vue'

export default {
  name: 'AddPayableDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    invoice: {
      currency: 'EUR'
    },
    vendors: [],
    error: null,
    uploadPercentCompleted: 0,
    invoiceScan: null,
    showNewVendorDialog: false
  }),
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  beforeMount () {
    this.loadVendors()
  },

  methods: {
    loadVendors () {
      fetch('/api/accounting/vendors', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.vendors = data.map(vendor => {
            return {
              text: vendor.vendorName,
              value: vendor.vendorId
            }
          })
        })
    },

    savePayable () {
      fetch('/api/accounting/payables', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.invoice)
      })
        .then(response => {
          if (response.ok) {
            this.showDialog = false
            this.$emit('reload')
          } else {
            response.json().then(data => {
              this.error = data.statusText
            })
          }
        })
    },

    vendorAdded (vendor) {
      console.log('vendor added', vendor)
      this.vendors.push({
        text: vendor.vendorName,
        value: vendor.vendorId
      })
    }
  },
  components: { NewVendorDialog }
}
</script>
