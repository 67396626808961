<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="form">
        <v-card-title>Add New Ledger Account</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field data-cy="accountName" label="Account Name" v-model="account.accountName" />
            </v-col>
            <v-col>
              <v-select data-cy="accountType" :items="accountTypesAvailable" label="Account Type"
                v-model="account.accountType"></v-select>
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="formCancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="formSave" color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddEntryDialog',
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    error: null,
    account: {},
    accountTypesAvailable: [
      'ASSET',
      'LIABILITY',
      'EQUITY',
      'REVENUE',
      'EXPENSE'
    ]
  }),
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    save () {
      fetch('/api/accounting/accounts', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.account)
      })
        .then(response => {
          if (response.ok) {
            this.showDialog = false
            this.$emit('reload')
          } else {
            response.json().then(json => {
              this.error = json.message
            })
          }
        })
        .catch(error => {
          this.error = error.message
        })
    }
  }
}
</script>
