<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item.link" :to="item.link">{{ item.text }}</v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'AccountingHome',
  components: {
  },
  data () {
    return {
      tab: null,
      items: [
        {
          text: i18n.t('accounting.tab.overview'),
          link: '/accounting/overview'
        },
        {
          text: i18n.t('accounting.tab.invoicing'),
          link: '/accounting/invoicing'
        },
        {
          text: i18n.t('accounting.tab.accountsPayable'),
          link: '/accounting/accounts-payable'
        },
        {
          text: i18n.t('accounting.tab.accountsReceivable'),
          link: '/accounting/accounts-receivable'
        },
        {
          text: i18n.t('accounting.tab.bank'),
          link: '/accounting/bank'
        },
        {
          text: i18n.t('accounting.tab.cashFlow'),
          link: '/accounting/cash-flow'
        },
        {
          text: i18n.t('accounting.tab.vendors'),
          link: '/accounting/vendors'
        }
      ]
    }
  }
}
</script>
