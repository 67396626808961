<template>
  <div>
    <h2>Balance Sheet</h2>
    <div>
      <div class="balance-sheet">
        <table>
          <thead>
            <tr>
              <th>Assets</th>
              <th class="value">Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in balanceSheet.assets" :key="index">
              <td>
                <router-link :to="{ name: 'AccountingAccount', params: { accountId: item.account.accountId } }">{{
                  item.account.accountName }}</router-link>
              </td>
              <td class="value"> {{ formatMoney(item.balance, 'EUR') }}</td>
            </tr>
            <tr class="total-sum">
              <td>Total</td>
              <td class="value">{{ formatMoney(balanceSheet.assetsTotal, 'EUR') }}</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>Liabilities and Equity</th>
              <th class="value">Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in balanceSheet.liabilitiesAndEquity" :key="index">
              <td>
                <router-link :to="{ name: 'AccountingAccount', params: { accountId: item.account.accountId } }">{{
                  item.account.accountName }}</router-link>
              </td>
              <td class="value"> {{ formatMoney(reverseValue(item.balance), 'EUR') }}</td>
            </tr>
            <tr class="total-sum">
              <td>Total</td>
              <td class="value">{{ formatMoney(reverseValue(balanceSheet.liabilitiesAndEquityTotal), 'EUR') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <v-alert v-if="!balanceSheet.balanced" type="info">
        Assets and Liabilities and Equity do not match! Please reconcile.</v-alert>
    </div>

    <h2>Income Statement</h2>
    <div class="balance-sheet">
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th class="value">Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in balanceSheet.incomeStatement" :key="index">
            <td>
              <router-link :to="{ name: 'AccountingAccount', params: { accountId: item.account.accountId } }">{{
                item.account.accountName }}</router-link>
            </td>
            <td class="value"> {{ formatMoney(item.balance, 'EUR') }}</td>
          </tr>
          <tr class="total-sum">
            <td>Net income</td>
            <td class="value">
              <span :class="{ 'negative-value': balanceSheet.incomeStatementTotal < 0 }">
                {{ formatMoney(balanceSheet.incomeStatementTotal, 'EUR') }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import formatting from '@/mixins/formatting'

export default {
  name: 'HomeAccounting',
  components: {},
  mixins: [formatting],

  data: () => ({
    balanceSheet: {
      balanced: true
    }
  }),

  beforeMount () {
    this.loadBalanceSheet()
  },

  methods: {
    reverseValue (value) {
      return value * -1
    },

    loadBalanceSheet () {
      fetch('/api/accounting/balance/sheet', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.balanceSheet = data
        })
    }
  }
}
</script>

<style>
.balance-sheet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

TABLE {
  width: 100%;
}

.value {
  text-align: right;
}

.total-sum {
  font-weight: bold;
}

.negative-value {
  color: red;
}
</style>
