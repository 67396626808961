<template>
  <div>
    <BalanceSheet />
  </div>
</template>

<style scoped>
.large {
  font-size: 150%;
  font-weight: bold;
}
</style>

<script>
import BalanceSheet from './BalanceSheet'

export default {
  name: 'HomeAccounting',
  components: {
    BalanceSheet
  }

}
</script>
