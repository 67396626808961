<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="formVendor">
        <v-card-title>Add new vendor</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field data-cy="vendorName" label="Name" v-model="vendor.vendorName" :rules="[required]" />
            </v-col>
            <v-col>
              <v-text-field data-cy="vendorEmail" label="Email" v-model="vendor.vendorEmail" />
            </v-col>
            <v-col>
              <v-text-field data-cy="vendorPhone" label="Phone" v-model="vendor.vendorPhone" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field data-cy="vendorTaxId" label="Tax ID" v-model="vendor.vendorTaxId" />
            </v-col>
            <v-col>
              <v-text-field data-cy="vendorIban" label="IBAN" v-model="vendor.vendorIban" :rules="[required]" />
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="cancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="save" color="primary" @click="saveVendor">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewVendorDialog',

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    error: null,
    vendor: {}
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    required (value) {
      return value ? true : 'This field is required'
    },

    saveVendor () {
      fetch('/api/accounting/vendors', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.vendor)
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(response.statusText)
          }
        })
        .then(data => {
          this.showDialog = false
          this.$emit('newVendor', data)
        })
        .catch(error => {
          this.error = error.message
        })
    }
  }
}
</script>
