<template>
  <v-data-table :items="vendors" :headers="headers">

  </v-data-table>
</template>

<script>
export default {
  name: 'AccountingVendors',

  data: () => ({
    headers: [
      { text: 'Name', value: 'vendorName' },
      { text: 'Phone', value: 'vendorPhone' },
      { text: 'Email', value: 'vendorEmail' },
      { text: 'IBAN', value: 'vendorIban' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    vendors: []
  }),

  beforeMount () {
    this.loadVendors()
  },

  methods: {
    loadVendors () {
      fetch('/api/accounting/vendors', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Error loading vendors')
          }
        })
        .then(response => {
          this.vendors = response
        })
    }
  }
}
</script>
