<template>
  <div>
    <div class="account-header">
      <div class="label">Account Name:</div>
      <div class="value">{{ account.accountName }}</div>

      <div class="label">Type:</div>
      <div class="value">{{ account.accountType }}</div>

      <div class="label">Number:</div>
      <div class="value">{{ account.accountNumber }}</div>
    </div>

    <v-data-table :headers="headers" :items="entries">
      <template v-slot:[`item.amount`]="{ item }">
        <span :class="{ 'negative-value': item.amount.amount < 0 }">{{ formatMoney(item.amount.amount,
          item.amount.curreny)
        }}</span>
      </template>
      <template v-slot:[`item.balance`]="{ item }">
        <span :class="{ 'negative-value': item.balance < 0 }">{{ formatMoney(item.balance,
          item.amount.curreny)
        }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.account-header {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.account-header .label {
  font-weight: bold;
}

.account-header .value {
  text-align: left;
  white-space: nowrap;
}

.negative-value {
  color: red;
}
</style>

<script>
import formatting from '@/mixins/formatting'

export default {
  name: 'AccountEntries',
  mixins: [formatting],
  data: () => ({
    headers: [
      { text: 'Entry Date', value: 'entryDate' },
      { text: 'Description', value: 'description' },
      { text: 'Amount', value: 'amount', align: 'right' },
      { text: 'Balance', value: 'balance', align: 'right' }
    ],
    account: {},
    entries: []
  }),
  beforeMount () {
    this.loadAccount(this.$route.params.accountId)
    this.loadEntries(this.$route.params.accountId)
  },

  methods: {
    loadAccount (accountId) {
      fetch(`/api/accounting/accounts/${accountId}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.account = data
        })
    },

    loadEntries (accountId) {
      fetch(`/api/accounting/accounts/${accountId}/entries`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.entries = []
          let accumulatedBalance = 0

          data.forEach(entry => {
            accumulatedBalance += entry.amount.amount

            this.entries.push({
              entryDate: entry.entryDate,
              description: entry.description,
              amount: entry.amount,
              balance: accumulatedBalance
            })
          })
        })
    }
  }
}
</script>
