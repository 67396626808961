<template>
  <div>
    <v-data-table :headers="headers" :items="payables">
      <template v-slot:top>
        <v-toolbar>
          <v-btn data-cy="addPayableDialog" color="primary" dark @click="showAddDialog = true">Add</v-btn>
          <AddPayableDialog :show="showAddDialog" @input="showAddDialog = $event" v-on:reload="loadPayables" />
        </v-toolbar>
      </template>
      <template v-slot:[`item.payableAmount`]="{ item }">
        {{ formatMoney(item.payableAmount, item.currency) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon data-cy="recordPayment" @click="makePayment()">mdi-cash-clock</v-icon>
        <PayablePaymentDialog :payable="item" :show="showPayablePaymentDialog" @input="showPayablePaymentDialog = $event"
          v-on:reload="loadPayables" />
      </template>
    </v-data-table>

    <h1>Accounts Payable</h1>
    <ul>
      <li>Facilty to record payment</li>
      <li>Future: integration with bank account to perform payment</li>
    </ul>
  </div>
</template>

<script>
import AddPayableDialog from './AddPayableDialog.vue'
import PayablePaymentDialog from './PayablePaymentDialog.vue'
import formatting from '@/mixins/formatting'

export default {
  name: 'AccountsPayable',

  components: { AddPayableDialog, PayablePaymentDialog },
  mixins: [formatting],

  data: () => ({
    headers: [
      { text: 'Invoice Date', value: 'payableDate' },
      { text: 'Entry Date', value: 'entryDate' },
      { text: 'Vendor', value: 'vendorName' },
      { text: 'Amount', value: 'payableAmount' },
      { text: 'Paid', value: 'paid' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    payables: [],
    showAddDialog: false,
    showPayablePaymentDialog: false
  }),

  beforeMount () {
    this.loadPayables()
  },

  methods: {
    loadPayables () {
      fetch('/api/accounting/payables', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Error loading payables')
          }
        })
        .then(response => {
          this.payables = response
        })
    },

    makePayment () {
      this.showPayablePaymentDialog = true
    }
  }
}
</script>
