<template>
  <div>
    <div class="account-header">
      <div class="label">Account Name:</div>
      <div class="value">{{ account.accountName }}</div>

      <div class="label">Type:</div>
      <div class="value">{{ account.accountType }}</div>

      <div class="label">Number:</div>
      <div class="value">{{ account.accountNumber }}</div>
    </div>

    <v-alert v-if="error" type="error">{{ error }}</v-alert>

    <v-data-table :headers="headers" :items="entries" :items-per-page="-1">
      <template v-slot:top>
        <v-toolbar>
          <v-btn data-cy="addPayableDialog" color="primary" dark @click="showAddDialog = true">Add</v-btn>
          <AddEntryDialog :show="showAddDialog" :account="account" @input="showAddDialog = $event"
            v-on:reload="loadEntries(account.accountId)" />
          <v-spacer></v-spacer>
          <v-btn data-cy="addLedgerAccount" @click="newAccount()">New Ledger Account</v-btn>
          <AddAccountDialog :show="showAddAccountDialog" @input="showAddAccountDialog = $event"
            @reload="loadAllAccounts()" />
          <v-btn data-cy="reconcile" @click="reconcile(account.accountId)">Reconcile</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="deleteEntry(account.accountId, item.id)">mdi-delete</v-icon>
      </template>
      <template v-slot:[`item.transactionAmount`]="{ item }">
        <span :class="{ 'negative-value': item.transactionAmount.amount < 0 }">{{
          formatMoney(item.transactionAmount.amount,
            item.transactionAmount.curreny)
        }}</span>
      </template>
      <template v-slot:[`item.account`]="{ item }">
        <v-select data-cy="reconcileAccount" :items="accountsAvailable" v-model="item.reconcileAccount"></v-select>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.account-header {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.account-header .label {
  font-weight: bold;
}

.account-header .value {
  text-align: left;
}

.negative-value {
  color: red;
}
</style>

<script>
import formatting from '@/mixins/formatting'
import AddEntryDialog from '@/views/accounting/AddEntryDialog.vue'
import AddAccountDialog from '@/views/accounting/AddAccountDialog.vue'

export default {
  name: 'BankAccount',
  components: { AddEntryDialog, AddAccountDialog },
  mixins: [formatting],
  data: () => ({
    headers: [
      { text: 'Actions', value: 'actions' },
      { text: 'Entry Date', value: 'entryDate' },
      { text: 'Statement Date', value: 'statementDate' },
      { text: 'Transaction Date', value: 'transactionDate' },
      { text: 'Description', value: 'transactionDescription' },
      { text: 'Amount', value: 'transactionAmount', align: 'right' },
      { text: 'Account', value: 'account', align: 'right' }
    ],
    error: null,
    account: {},
    entries: [],
    accountsAvailable: [],
    showAddDialog: false,
    showAddAccountDialog: false
  }),

  beforeMount () {
    this.loadAccount(this.$route.params.accountId)
    this.loadEntries(this.$route.params.accountId)
    this.loadAllAccounts()
  },

  methods: {
    deleteEntry (accountId, entryId) {
      fetch(`/api/accounting/bank-accounts/${accountId}/entries/${entryId}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.loadEntries(accountId)
          } else {
            this.error = 'Error deleting entry'
          }
        })
    },

    newAccount () {
      this.showAddAccountDialog = true
    },

    loadAccount (accountId) {
      fetch(`/api/accounting/accounts/${accountId}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.account = data
        })
    },

    loadAllAccounts () {
      // TODO filter only relevant accounts (backend?)
      fetch('/api/accounting/accounts', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.accountsAvailable = []

          data.forEach(account => {
            if (account.accountId === this.account.accountId) return

            this.accountsAvailable.push({
              text: account.accountName,
              value: account.accountId
            })
          })
        })
    },

    loadEntries (accountId) {
      fetch(`/api/accounting/bank-accounts/${accountId}/entries`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.entries = data
        })
    },

    reconcile (accountId) {
      // Filter items with the 'reconcileAccount' property and extract accountId and reconcileAccount
      const reconcilableItems = this.entries
        .filter(item => Object.prototype.hasOwnProperty.call(item, 'reconcileAccount'))
        .map(item => ({
          entryId: item.id,
          reconcileAccountId: item.reconcileAccount
        }))

      fetch(`/api/accounting/bank-accounts/${accountId}/reconcile`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reconcilableItems)
      })
        .then(response => {
          if (response.ok) {
            this.loadEntries(accountId)
          } else {
            this.error = response.statusText
          }
        })
    }

  }
}
</script>
